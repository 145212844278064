import {
  API_FETCH_CRYPTOS_ROUTE,
  API_FETCH_ENVIRONMENT_ROUTE,
  API_FETCH_LIVESHOW_POOLS,
  API_FETCH_PAYMENT_PACKAGES_ROUTE,
  API_FETCH_POOLS,
  API_FETCH_SOCIALS_ROUTE,
  API_USER_VERIFY_EMAIL_ROUTE,
} from "../../utils/consts";
import {
  editAppSettings,
  removeLoadings,
  setError,
  setLoadings,
  setMessage,
} from "../reducers/rootReducer";
import {
  BALANCE,
  CAMERA,
  CAMERAS,
  CHAT,
  CRYPTOS,
  HIGHLIGHT,
  LIVESHOWS,
  MAINTANCE_REMOVE,
  MAINTANCE_SET,
  MEMBER_BONUS,
  ONLINES,
  PACKAGES,
  POOLS,
  SOCIALS,
  USERS_ONLINE,
  USER_EMAIL,
} from "../types";
import axios from "../../utils/axios";
import {
  addNotifications,
  logoutUser,
  setConversations,
  setNotifications,
  userSignin,
  userSigninEnv,
} from "./userAction";
import Cookies from "universal-cookie";
const setHouseCameras = (data) => {
  return {
    type: CAMERAS,
    payload: { data },
  };
};

const setMainCamera = (data) => {
  return {
    type: CAMERA,
    payload: { data },
  };
};

const setPerformersOnline = (data) => {
  return {
    type: ONLINES,
    payload: { data },
  };
};
const setLiveshows = (data) => {
  return {
    type: LIVESHOWS,
    payload: { data },
  };
};
const setMaintance = () => {
  return {
    type: MAINTANCE_SET,
  };
};
const removeMaintance = () => {
  return {
    type: MAINTANCE_REMOVE,
  };
};
const setMemberJoinBonus = (data) => {
  return {
    type: MEMBER_BONUS,
    payload: { data },
  };
};
const setChat = (data) => {
  return {
    type: CHAT,
    payload: { data },
  };
};
const weekendHighlightVideos = (data) => {
  return {
    type: HIGHLIGHT,
    payload: { data },
  };
};

const setUsersOnline = (data) => {
  return {
    type: USERS_ONLINE,
    payload: { data },
  };
};

export const setBalance = (data) => {
  return {
    type: BALANCE,
    payload: { data },
  };
};

const AllCryptos = (data) => {
  return {
    type: CRYPTOS,
    payload: { data },
  };
};

const AllSocials = (data) => {
  return {
    type: SOCIALS,
    payload: { data },
  };
};

const AllPackages = (data) => {
  return {
    type: PACKAGES,
    payload: { data },
  };
};

export const setPools = (data) => {
  return {
    type: POOLS,
    payload: { data },
  };
};

export const setEmail = (data) => {
  return {
    type: USER_EMAIL,
    payload: { data },
  };
};

export const updatePool =
  (type, liveshowId = null) =>
  async (dispatch) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          (type === "all"
            ? API_FETCH_POOLS
            : API_FETCH_LIVESHOW_POOLS + liveshowId + "/pools"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          credentials: "include",
        }
      );
      //response.data
      const data = await response.json();

      if (data) {
        dispatch(setPools(data.data.pools));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const VerifyEmail = () => async (dispatch) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_VERIFY_EMAIL_ROUTE,
      null,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      dispatch(setMessage(data.message, data.type));
    }
  } catch (error) {
    dispatch(setError(error));
  }
};
export const getEnvironment =
  (type = null, checkPackage = false) =>
  async (dispatch) => {
    const cookies = new Cookies();
    try {
      let res;
      if (localStorage.getItem("user_age_check") == "true") {
        sessionStorage.setItem("Wait_env", true);
        res = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            API_FETCH_ENVIRONMENT_ROUTE +
            (type ? "/" + type : ""),
          {
            withCredentials: localStorage.getItem("access_token")
              ? true
              : false,
            headers: localStorage.getItem("access_token")
              ? {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization:
                    `Bearer ` + localStorage.getItem("access_token"),
                }
              : {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
          }
        );

        if (res.data) {
          if (
            checkPackage &&
            res.data.data.balance &&
            res.data.data.balance.package &&
            !res.data.data.balance.package.is_valid
          ) {
            return;
          }

          if (res.data.data.balance && res.data.data.balance.credits) {
            if (res.data.data.balance.credits.total)
              res.data.data.balance.credits.total =
                Math.round(res.data.data.balance.credits.total * 10) / 10;

            res.data.data.balance.credits.today =
              Math.round(res.data.data.balance.credits.today * 10) / 10;
            res.data.data.balance.credits.thisMonth =
              Math.round(res.data.data.balance.credits.thisMonth * 10) / 10;

            if (res.data.data.balance && res.data.data.balance.tips) {
              res.data.data.balance.tips.today =
                Math.round(res.data.data.balance.tips.today * 10) / 10;
              res.data.data.balance.tips.thisMonth =
                Math.round(res.data.data.balance.tips.thisMonth * 10) / 10;
            }
          }
          res.data.data.userIp &&
            res.data.data.userIp !== null &&
            sessionStorage.setItem("userIp", res.data.data.userIp);

          res.data.data.freeViewExpired !== null &&
            localStorage.setItem("freeView", res.data.data.freeViewExpired);

          res.data.data.uniqueChatId !== null &&
            localStorage.setItem("uniqueChatId", res.data.data.uniqueChatId);
          res.data.data.freeViewTimestamp !== null &&
            localStorage.setItem(
              "freeViewTimestamp",
              res.data.data.freeViewTimestamp
            );

          res.data.data.userData.email_verified !== null &&
            localStorage.setItem(
              "email_verified",
              res.data.data.userData.email_verified
            );

          res.data.data.userData.email_verified !== null &&
            dispatch(setEmail(res.data.data.userData.email_verified));
          res.data.data.userData.is_premium !== null &&
            localStorage.setItem(
              "is_premium",
              res.data.data.userData.is_premium
            );
          res.data.data.userEnv &&
            dispatch(userSigninEnv(res.data.data.userEnv));
          res.data.data.notification_data &&
            dispatch(
              setNotifications({
                notification_data: res.data.data.notification_data,
              })
            );

          res.data.data.houseCameras &&
            res.data.data.houseCameras.length &&
            dispatch(setHouseCameras(res.data.data.houseCameras));
          res.data.data.chat &&
            res.data.data.chat.length &&
            dispatch(setChat(res.data.data.chat));
          if (window.location.pathname == "/" && res.data.data.mainCamera) {
            sessionStorage.setItem("Initial", true);
            dispatch(setMainCamera(res.data.data.mainCamera));
          }
          sessionStorage.setItem("Wait_env", false);
          res.data.data.liveShows &&
            res.data.data.liveShows !== null &&
            dispatch(setLiveshows(res.data.data.liveShows));
          res.data.data.performersOnline &&
            res.data.data.performersOnline.length &&
            dispatch(setPerformersOnline(res.data.data.performersOnline));
          res.data.data.weekendHighlightVideos &&
            dispatch(
              weekendHighlightVideos(res.data.data.weekendHighlightVideos)
            );
          res.data.data.usersOnline &&
            res.data.data.usersOnline !== null &&
            res.data.data.usersOnline.length &&
            dispatch(setUsersOnline(res.data.data.usersOnline));
          res.data.data.memberJoinBonus &&
            res.data.data.memberJoinBonus !== null &&
            dispatch(setMemberJoinBonus(res.data.data.memberJoinBonus));
          res.data.data.balance &&
            res.data.data.balance.credits &&
            dispatch(setBalance(res.data.data.balance));
          /*         (res.data.data.notices && res.data.data.notices.notices && res.data.data.notices.notices.length > 0) && dispatch(setMessage(res.data.data.notices.notices, 'warning')); */
          res.data.data.notification_data &&
            res.data.data.notification_data &&
            res.data.data.notification_data.length > 0 &&
            dispatch(
              dispatch(addNotifications(res.data.data.notification_data))
            );
          res.data.data.conversations &&
            res.data.data.conversations.length > 0 &&
            dispatch(setConversations(res.data.data.conversations));
          res.data.data.maintenanceActive &&
            (res.data.data.maintenanceActive === 1
              ? dispatch(setMaintance())
              : dispatch(removeMaintance()));

          res.data.data.pools &&
            res.data.data.pools.length > 0 &&
            dispatch(setPools(res.data.data.pools));

          res.data.data.userData.account_status !== null &&
            localStorage.setItem(
              "account_status",
              res.data.data.userData.account_status
            );
          res.data.data.userData.isLoggedIn !== null &&
            res.data.data.userData.isLoggedIn == false &&
            localStorage.getItem("access_token") &&
            dispatch(logoutUser());

          res.data.data.userData.lushStatus !== null &&
            localStorage.setItem(
              "lush_status",
              res.data.data.userData.lushStatus
            );
          res.data.data.privateMessagePrice !== null &&
            localStorage.setItem(
              "privateMessagePrice",
              res.data.data.privateMessagePrice
            );
        } else {
          dispatch(setMessage(res.data.message, "error"));
          sessionStorage.setItem("Wait_env", false);
        }
      }
    } catch (error) {
      console.log(error);
      sessionStorage.setItem("Wait_env", false);
      /* dispatch(removeLoadings('camera')) */
    }
  };

export const getCryptos = () => async (dispatch) => {
  try {
    dispatch(setLoadings("crypto"));

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_CRYPTOS_ROUTE,
      {
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (res.data) {
      dispatch(AllCryptos(res.data));
    }

    dispatch(removeLoadings("crypto"));
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentPackages = () => async (dispatch) => {
  try {
    dispatch(setLoadings("packages"));

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_PAYMENT_PACKAGES_ROUTE,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.data) {
      dispatch(AllPackages(res.data.data.packages));
      dispatch(
        editAppSettings({
          credit_card_provider: res.data.data.credit_card_provider,
        })
      );
    }

    dispatch(removeLoadings("packages"));
  } catch (error) {
    console.log(error);
  }
};

export const getSocials = () => async (dispatch) => {
  try {
    dispatch(setLoadings("socials"));

    const res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_FETCH_SOCIALS_ROUTE,
      {
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (res.data) {
      dispatch(AllSocials(res.data));
    }

    dispatch(removeLoadings("socials"));
  } catch (error) {
    console.log(error);
  }
};
