import React, { useCallback } from "react";
import { Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Logout from "../components/Logout/Logout";
import history from "../redux/history";
import { connect, useDispatch } from "react-redux";
import { setMessage } from "../redux/reducers/rootReducer";
import { setBinom, UpdateBinom } from "../redux/actions/userAction";
import { lazy } from "react";
import { getEnvironment } from "../redux/actions/environmentAction";
const Magic = lazy(() => import("../pages/Magic/Magic"));
const Account = lazy(() => import("../pages/Account/Account"));
const Privacy = lazy(() => import("../pages/Privacy/Privacy"));
const TermsOfUse = lazy(() => import("../pages/TermsOfUse/TermsOfUse"));
const Page404 = lazy(() => import("../pages/Page404/Page404"));
const Support = lazy(() => import("../pages/Support/Support"));
const Liveshow = lazy(() => import("../pages/Liveshow/Liveshow"));
const Housemates = lazy(() => import("../pages/Housemates/Housemates"));
const HousemateProfile = lazy(() =>
  import("../pages/HousemateProfile/HousemateProfile")
);
const Home = lazy(() => import("../pages/Home/Home"));
const Law = lazy(() => import("../pages/18_Law/18_Law"));
const Blog = lazy(() => import("../pages/Blog/Blog"));
const Post = lazy(() => import("../pages/Post/Post"));
const AboutWHX = lazy(() => import("../pages/AboutWHX/AboutWHX"));
const Events = lazy(() => import("../pages/Events/Events"));
const Media = lazy(() => import("../pages/Media/Media"));
const Sitemap = lazy(() => import("../pages/Sitemap/Sitemap"));
const Cameras = lazy(() => import("../pages/Cameras/Cameras"));

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

function Router({ session }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBuyDialog = useCallback((json) => {
    try {
      let response = JSON.parse(json);
      let message = null;
      if (response.package_type === "camera_access") {
        message = `Purchased camera access for ${response.days_access} days.`;
      } else if (response.package_type === "token") {
        message = `Purchased ${Math.floor(response.token_amount)} credits.`;
      }

      if (response.vendor && response.vendor === "paypal") {
        message += " Balance update can take up to 5 minutes.";
      }

      if (message) dispatch(setMessage(message));

      const newUrl = window.location.href.split("?")[0];
      window.history.pushState({ path: newUrl }, "", newUrl);
    } catch (e) {}
  }, []);

  const handleNodaBuyDialog = useCallback((response) => {
    if (response) {
      if (response === "done") {
        dispatch(setMessage("Thank you for your purchase!"));
      } else {
        dispatch(setMessage(`Purchase was declined!`, "error"));
      }
      const newUrl = window.location.href.split("?")[0];
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const json = queryParams.get("custom1");
    if (json) handleBuyDialog(json);
    const nodaResult = queryParams.get("result");
    const nodaSignature = queryParams.get("signature");
    if (nodaResult && nodaSignature) handleNodaBuyDialog(nodaResult);
    if (queryParams) {
      const clickid = queryParams.get("clickid");
      if (clickid) {
        let binomObject = {};
        for (const [key, value] of new URLSearchParams(
          window.location.search
        ).entries()) {
          binomObject[key] = value;
        }
        console.log('here')
        dispatch(setBinom(binomObject));
        if (queryParams.get("uclick") && queryParams.get("uclickhash")) {
          if (session?.user) {
            dispatch(UpdateBinom({ binom: binomObject }));
            navigate("/account");
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (location.pathname === "/login" && session.user) navigate("/");

    if (!session.user) {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("core_api_token");
      localStorage.removeItem("access_token");
      window.sessionStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense
      fallback={
        <div className={`loader-wrapper`}>
          <div className="loader"></div>
        </div>
      }
    >
      <Routes Routes history={history}>
        <Route path="/" element={<Home session={session} />} />
        <Route path="/login" element={<Home session={session} />} />
        <Route path="/register" element={<Home session={session} />} />
        <Route path="/reset-password" element={<Home session={session} />} />
        <Route path="/live-cams" element={<Cameras session={session} />} />
        <Route path="/live-cams/:camera" element={<Home session={session} />} />
        <Route
          path="/live-cams/bedroom/:camera"
          element={<Home session={session} />}
        />
        <Route path="/account" element={<Account session={session} />} />
        <Route path="/housemates" element={<Housemates session={session} />} />
        <Route
          path="/housemate/:username"
          element={<HousemateProfile session={session} />}
        />
        <Route
          path="/liveshows/:slug"
          element={<Liveshow session={session} />}
        />
        <Route path="/media" element={<Media session={session} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/privacy" element={<Privacy session={session} />} />
        <Route path="/terms" element={<TermsOfUse session={session} />} />
        <Route path="/law" element={<Law session={session} />} />
        <Route path="/magic" element={<Magic session={session} />} />
        <Route path="/blog" element={<Blog session={session} />} />
        <Route path="/post/:slug" element={<Post session={session} />} />
        <Route
          path="/tag/:slug"
          element={<Blog session={session} tag={true} />}
        />
        {/* <Route path='/test' element={<Test />} /> */}
        <Route path="/support" element={<Support session={session} />} />
        <Route path="/about" element={<AboutWHX session={session} />} />
        <Route path="/events" element={<Events session={session} />} />
        <Route path="/sitemap" element={<Sitemap session={session} />} />
        <Route path="*" element={<Page404 session={session} />} status={404} />
      </Routes>
    </Suspense>
  );
}

export default connect(mapStateToProps)(Router);
