import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducers/rootReducer";
import { initialState } from "./state";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "session",
    "magic",
    "medias",
    "memberJoinBonus",
    "balance",
    "pricing",
    "userInShow",
    "conversations",
    "models",
    "loadings",
    "cameras",
    "camera",
    "liveshows",
    "onlines",
    "highlight",
    "tab",
    "purchaseType",
    "profile",
    "media",
    "gallery",
    "usersInChat",
    "usersOnline",
    "privateShowRequests",
    "isInPrivateShow",
    "socials",
    "cryptos",
    "packages",
    "binom",
    "notifications",
    "pinnedMessages",
    "model_earnings",
    "posts",
    "models",
    "events",
    "watching",
    "modal",
    "app"
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);
const Persistor = persistStore(store);

export { store };
export { Persistor };
